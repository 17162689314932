import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TextSection from "../../components/textSection"
import ExperienceCard from "../../components/experienceCard"

const FieldsOfExpertise = (props) => {
	const data = useStaticQuery(graphql`
		query MyQuery {
			dnhCms {
				subPages(where: {pageTitle: "Fields of expertise"}) {
					id
					bannerHeading
					pageTitle
					pageDescription
					heading
					copy
					pageBanner {
						url
					}
					infoCards {
						id
						heading
						copy
						icon {
							url
							fileName
						}
					}
				}
			}
		}
	`)
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.subPages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}
					bannerHeading={data.dnhCms.subPages[0].bannerHeading}
					bannerUrl={data.dnhCms.subPages[0].pageBanner.url}>
				<SEO title={data.dnhCms.subPages[0].pageTitle}
					description={data.dnhCms.subPages[0].pageDescription} />
				<TextSection heading={data.dnhCms.subPages[0].heading}
							copy={data.dnhCms.subPages[0].copy}
							textColor="blue"/>
				<ExperienceCard cardData={data.dnhCms.subPages[0].infoCards} columnNumb="three"/>
			</Layout>
		</>
	)
}

FieldsOfExpertise.propTypes = {
	location: PropTypes.object
}

export default FieldsOfExpertise
